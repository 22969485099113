import '@brikit/tabjay-ui-kit/src/assets/css/styles-index.scss'
import dynamic from 'next/dynamic'

const LoadableApp = dynamic(() => import('components/loadable_app'))

export default function App({Component, pageProps, session}) {
  return (
    <LoadableApp Component={Component} pageProps={pageProps} session={session} />
  )
}
